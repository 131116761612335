import "../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VwXKbMBC95yt06Ux8UMYmceoot/5Ar711ZLTApkKi0mJwOvn3DpKxAePE7eTGrLT7dt97rO5+rn4/QflCS/bnhrFMQ8tzZxvBVs83jCn0lZZ7EQ66wEvtCbM9T60hMBQPOBjVHZZoeIOKCsFW62UVEkrZ9rEkxt5u7nrQ1SeDLq8BTQLoVqa/cmdrowRrCiTo8sY5sU4BmBck2P1yV3QxuwOXadvwvWCyJjuqfR9qE7TEyUnjM+tKwVJZIUmNrwEks4Z4c6j6uFyeAw8KPoSClfVIaI1gDrQk3MEsT4EWhQ7SeDm1ui5NHMTlaPjWEtlSsORhArMOMJNbm0hC6NfjKwi2k+6Wc5llaIDHeLk4m2kdZ0qttm6SE5jxkFqjpNsvRj08hh5OUn7pivTsrzYHSbbWKXCCraqWeatRjQG03IPjaDwq+Bau8pixOCVzJxXWXrCHWPJjv6VgCFx3JDXmhiNB6U/hwRBfJ3rJrbe6ju7qeV0lEVdDFtgKgw7sEj61JPhxy9fLL4sPRWj94mrCh87ncxmRwcGlonP89x04LWOBSiqFJhcsqdreJEcO0ejQ14HKWb7eZXheo1eORkF72BC9K858vIn/tm25L6TqNoqxBv5nagJHeObRp1D/zDAXpjwSdRhi4u35teZJOuqOc1kJ9jgZUM5uzP6n6Ie1NXUqnAJX/8OZLFEHGQtwSANbDSLnXAbLVtKBoXG7QlRaplBYrcCF3i+6dHBxTPr2Yh6m1vDKYdl7ezBnMl2l6bx2Zyr4SqbAt0ANgHlH3f4hEKxApeLVkcIDbDWPfXlhjzDHvpjF7V4wHjrvRG+crJ77h+h0H7TGyqMftQbj1q77fy+LOBBjYvd/7XF+0mucHCbod4SxrpR6tDWSiTPg7rgh37foeJFes5OPmFM3ZheRtjXRxNaHhzk+GJtJpfyzex6vrbe/B9ZEmSMKAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = '_1q9emjt9';
export var fontItem = '_1q9emjtd';
export var fontItemContainer = '_1q9emjtc';
export var fontLabel = '_1q9emjte';
export var menu = '_1q9emjt2';
export var menuTrigger = '_1q9emjt3';
export var notFound = '_1q9emjtg';
export var preViewLabelWrapper = '_1q9emjt1';
export var searchIcon = '_1q9emjtb';
export var searchInput = '_1q9emjta';
export var selectedIcon = '_1q9emjtf';
export var settingWrapper = '_1q9emjt0';
export var shapeIndicator = '_1q9emjt8';
export var snapshot = '_1q9emjt6';
export var snapshotContainer = '_1q9emjt4';
export var snapshotLabel = '_1q9emjt7';
export var snapshotTitle = '_1q9emjt5';