import "../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VT0W7bMAx8z1fwZcD2oMJOvSxTvka2aIerTQkSnbgd9u+F7Mx1GrdFB+zJgEgej3fnu7YZnkyZwe8NgHeRhBxrCNgaoRMeNgCdCQ2xEuc1FIUfDps/m7tpLB/HaseiIj2hhpMJX5UydU2ManrvviWQyrUuvKoLDqJ8oM6ERzU2jK3j2BmpOYqGXZYt9m3HfZaib82jhrrF4YWhhjzzAyz779f701dZClhN11au7TtOlcZ4DXs/dnljLXGjId++gi3+CXZ3BTvpmRgvgL9PNiwXH9Y2mZYaViTYRQ0VsmBIz6ULFoMKxlIf5ztKUz00wfVs1ZoLi3LEyrG9dqMjVseLGXleXG5YCcp8xO6z6vzqo1CdlrIgy/KiM1k5JpmyL7OOkyrzuh8fZ7A0ET+bwhv+NyyjNxWqEuWMyAtC+48JDfF9OqtGmEFd5Nju91cS/PwfG2d0M6L/daLYvZHJG31Gw5HtO4F9yZEpo2t7wSnGIq7T6ZcDCFP0sqmd0qTCE7JEDew4Be8ZdRHWzcQEAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudWrapper = 'lgxzab4';
export var avatarWrapper = 'lgxzab2';
export var card = 'lgxzab5';
export var cardDescription = 'lgxzab8';
export var cardText = 'lgxzab6';
export var cardTitle = 'lgxzab7';
export var cloudSvgContainer = 'lgxzaba';
export var cloudTips = 'lgxzab9';
export var header = 'lgxzab0';
export var subTitle = 'lgxzab1';
export var workspaceNameWrapper = 'lgxzab3';