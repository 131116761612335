import "../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VX7W7bIBT936dAkya1UonsNMk2+hh7gAmba5uNAALSOKv67hNgJ5g4abd2fyKZj/tx7rmHm8WPkur1nukCPd8gxLjVgh4IagT0jzcIUcFbibmDrY2LGCTzG1tqWi6xU5qgcqn7x5uXm8VorAzGwvHWqD1B5eOrxmuQDkxiWkDjCFoWme1lsL3nzHUElUXx+R9Mh6iXKx2OttSnUMSPEPPe+CX/O/H8kHpeb+KFDnjbudN3pQwDgw1lfGcJWsf4tLLccSUJMiCo40/gV+udscoQpBUfAwz+bWe4/EVQMXW/YNzSSkAIY7zLoKE74aKTYAbDE0hnCZJKwtQC6dQTGLSo6RYMxbxWMiSrwcyV/4OuXr70TixpZZXYOZgwIGaN0M+ddbw54FpJB9KlLLhAjorWv1qjdpLhWgkPrmkrersp7tGmvEebh3tULNZ3/uhvzCWDfiD2cPqJmluMadNwCXjfcQfhbKOkw5b/hpFyCTarFIaaivrWMxphtFnpHmFUrnV/N5uN7w6/4aB3OCQ0rk3MW03lXMP4YjbCt2bHGQN5NHXaACG4ttz6rZANtprW4BGOrTGfuBa0hk4JX7mwfwJhP9a3mDJ7HSKc7xGlac3dgaBisZrv4NQQIbRxA7mOUH369HgOwMi18XueVVGDioCO91ikbSuVR16oPbBJFJt5KX0rIf35744adwnhUCcLtZKMmkMCclCyQdU0ZYzLlqCvg7CdWDgxFtZ7ezfJ4EssyGhiNduOUfXTtslMJ/2kDd9OY43GCCp1j6wSnGV3o6/T+TM4g1IybqCOZauV2G3lLNCBt7gCtwfP9CTRb9NEC58qKlCZC9IA4lkQGalmhSV1N6jo8zXYwpEzpI6wf804/41I5W6JoNZh1WB30HAXHAydUinn1JagTXaPprKwXBcXMrwMc/5UZQ/3SI9h0UQkz57zanZU+Bh5usp5u51yvlpokJ4Gg3jMKFtG4uRyHbN41d+Vhj5vkY8BQQRODURe5pMaez3wIA5/qUT/I/ScOWMzHRfiYHR8MMq5mYgKMTHS/J/8s95bZ6G3b5phz2bRfxpqpuqxyV6Hpe6Ps+8rkhyL9FDkQ83fI3ZO9ZlKX2BFgmL3/trBVnfUcpuEMjOUT3RtlQkllz74SWB8OgDEE/jqX5Pk9s88rfE1elcWL38AhvG3u+oNAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRight = '_1ap5wdpj';
export var avatarWrapper = '_1ap5wdp3';
export var fakeWrapper = '_1ap5wdp5';
export var goUpgrade = '_1ap5wdph';
export var goUpgradeWrapper = '_1ap5wdpi';
export var iconButton = '_1ap5wdpe';
export var label = '_1ap5wdpf';
export var labelWrapper = '_1ap5wdp2';
export var memberContainer = '_1ap5wdpa';
export var memberEmail = '_1ap5wdpd';
export var memberList = '_1ap5wdp8';
export var memberListItem = '_1ap5wdp9';
export var memberName = '_1ap5wdpc';
export var membersFallback = '_1ap5wdp6';
export var membersPanel = '_1ap5wdp7';
export var profileHandlerWrapper = '_1ap5wdp1';
export var profileWrapper = '_1ap5wdp0';
export var roleOrStatus = '_1ap5wdpb';
export var urlButton = '_1ap5wdp4';
export var workspaceLabel = '_1ap5wdpg';