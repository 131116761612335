import "../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwW7bMAy95yt4GbAeVNhpkqUq9iVDD7JF22xlSZDk2t2wfx8spYmTLIFTbCdbovz4HvlE37/U/Sp/zODXAkCSt0q8c6gUDk8LiE8myWEZyGgOpVFdq8dITzI0HPIs+zIuXzofqHpnpdEBdeBQog7onha/F/cpQz4vgzP9X/G8FSWyAkOPGAkIRbVmFLD1h2wAVkhJuuawsQNk404rXE2aFSYE03LIN3aY0FreRGt20lrYuBrfTRcUaeSgjcZxIzihPSXgQpSvtTOdlpDdLz2g8Dil90OKIFhDdaOobsJzpHv4iMObcF8ZE1VFGtkhwDyWRkvhxhoq4+4moA+3NjuqWSU118u5itAx4wk1Ko3eUwGojA7M0088g1hfhAg4BGYdtVNVR1BHx9N+G8+YN3SVMj2HhqRMDopwhwAqRdaTj+5uKCCLnhvb1jthpwxTUzqtRYvye3AdPl8kbZUosTFKojvvxOa61vMezlN7nf63mPTkAjc42osn9xbGjXSDsRxyO4A3iuSJ09KRM0XbCB6tVLuxrPkk+HhdriQvCoVzxQ5+mlj8n1Ke2H17ZNXiXwyPeLm26XLNYRTf+1271lls2E2y99Nqa4fd5QNoSbOdJZab7EhlmVp6jVrD1ncT4zghqfP7kXFWoAuVuPgT2bv1YZkQP9z6sY4V940j/Xpk4bn2/fRI/QP7B6aKPwcAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var divider = 'jgw4197';
export var listHeader = 'jgw4191';
export var main = 'jgw4190';
export var metaList = 'jgw4193';
export var propertyDocCount = 'jgw4196';
export var propertyIcon = 'jgw4194';
export var propertyName = 'jgw4195';
export var propertyNameIconEditable = 'jgw419c';
export var propertyRequired = 'jgw4199';
export var propertyRow = 'jgw4192';
export var propertyRowNamePopupRow = 'jgw419b';
export var spacer = 'jgw4198';
export var subListHeader = 'jgw419a';