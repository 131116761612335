import "../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61UwXKbMBC9+yv20pnmIA+4juMqM/kXgdawLUgaScS4nf57B+EQgSEkaU+MtNr33u6+ZfuzynVNKfzeAEhyphIXDqcK28cNhC+TZDH3pBWHXFdNrbqIqKhQjDzWjkOOyqPtrgthOOz2pn3c/Nlse+xdwD6T9CWHNEm+RLFv87w/GufpdGG5Vh6V5+CMyJFl6M+Igd8IKUkVHNKdCSmZthIth9S04HRFEp6F/cqYOJ1IIevDLNeVtnev75kVkhrH4TiSvA+yOjUcUkggiWL3IVYLW5BiXptp7qHP1cozR7+Qj3WE+5IdgoZwOCMVpedwSGKWh3WUTDi8i1KON8KGQUihCrS6ccyh96QK2CpRY0gILZngo7X6tVkDw/fFBI+tZw5zraSwl6jNb1bQuhhdrJccEiZ9u+/69oYqrE0pHLlIVN5Y1z02mnrjDhqyj+7BnOHzj4LUpFh5Lefh6ueFBbvZjJfQwC4/u1JX52Tae11PXY3rw3H1gqkBqu7RS4W7a4WLEzOW6thFg4jTux0yJkx6wpGy/Yptbs0cyXgCsbgKmRVKTjdgZNYBqPinvy4qGWGV096kx5mqZ+dx+Ow86D/OY22NZ+bxFxB1Os28BgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = 'klcomid';
export var believerPrice = 'klcomih';
export var believerPriceCaption = 'klcomii';
export var believerPriceWrapper = 'klcomig';
export var believerSubtitle = 'klcomif';
export var believerTitle = 'klcomie';
export var billingFrequency = 'klcomi7';
export var billingHistorySkeleton = 'klcomic';
export var currentPlan = 'klcomi4';
export var currentPlanName = 'klcomia';
export var history = 'klcomi1';
export var historyContent = 'klcomi2';
export var noInvoice = 'klcomi9';
export var paymentMethod = 'klcomi8';
export var planAction = 'klcomi5';
export var planCard = 'klcomi3';
export var planPrice = 'klcomi6';
export var subscription = 'klcomi0';
export var subscriptionSettingSkeleton = 'klcomib';