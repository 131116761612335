import "../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51V227bMAx9z1fwZUCzQoGdJlmroF8yFIMi0bY2RxIkunE27N8Hy3bl3Ip2D0kgkjo8PBSZxY/1E66WWfZdCRIM946Oz+QbfIE/MwBpa+s5vAp/x5goCm2QuVpIrGyt0LPon29nf2eLASiP95QOrhZHDkWN7XYG8Zcp7VGStoZ3wM3edJ6DVlRxyLPsS3csheOQL107BV1eB42xG5cSHHxn6b4vkBPYQwRzQiltSg5ZF+ps0D0xj7Ug/YqdlazjIEUt79jDYu1auB+U8ELpljnrHHomjKysZxXqsqI5fAWWz7vbNRbEob85oRPxbsPEqDncQ75xbcSxr+iL2h44VFopNNNaVu8Is7opzFvteebaQWyAnZC/Sm8bo9i1tlcdj7eGA+y1GWrmsDrr1zrSiplL3zHPL9NG3ffCl9pwYMm0s16h52CsiU2wDdXaYDIU1hAL+jdyyMcaO1Mh9ro+ctCmQq9pm57vxHJZJHlhghMeDZ2UwPnkof/nMGw+OwyxcY99UemFdpbx06vWJumzrDemZyIaslMW366z+NkE0sWRSWsIDXEITkhkO6QDYmQjal0apgn3gYNEQ+gviZ215ESdaG/D/C3mMLBeZ9n2hqKELbGA0hol/PGKqI+xnLFc1qa5uDXHU72Wo15J6gT99LF+eXv4lDxj7ofVeWoA2fjQaeCsHgH6CYjboQnDHE849pu6sLIJqCa7+lPzm/BEL6cTUtMxrsNJLh5vwY3Y/ASn5xVIED5bh+blneDdlQWRvDJ6h325zE5FHM8XXbrRjotnPrpSPjXNl29O843ns66s45/KPzDRwUc/BwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_59e4202';
export var searchInput = '_59e4205';
export var spacer = '_59e420b';
export var tagColorIcon = '_59e420d';
export var tagColorIconWrapper = '_59e420c';
export var tagEditIcon = '_59e420a';
export var tagSelectorItem = '_59e4209';
export var tagSelectorTagsScrollContainer = '_59e4208';
export var tagsEditorRoot = '_59e4201';
export var tagsEditorSelectedTags = '_59e4204';
export var tagsEditorTagsSelector = '_59e4206';
export var tagsEditorTagsSelectorHeader = '_59e4207';
export var tagsInlineEditor = '_59e4200';
export var tagsMenu = '_59e4203';