import "../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W247jKBB9769AkUbq1orIufSNVkv7H6vWqmLKNh0MFuBkMqv595XxJeA4nuz2PCWYoihOnTpVf+7xlBko0ZK/V5+nZ1NkO/LPHSHJN/9DiK4gFe7EyOrtjpCfd4Q8zew9X+4l/d7LzLnXmXOr5OrBn3fLPuyVN0m11IYRoQo0wr2FBmtvwIWtJJwaEykU0kzi98YXSJErKhyWlpEUlUPTfN5pw9FQA1zUlpFt5Y0dfnfUn2BEYubeJm4m5Ci4K5gPv1mWQtECRV44RjZJ66i2aKhFialjRGmF3lNtbOOq0qIPowLOhcoZSfoQMq0cteIHMnIAc08pZJl/j/9ePvhT2gontGLEoAQnDt59CSYXijpdMeLDCDFihT6g8UjtIN3nRteKj67wJtS/9yE+/RcHBxTS5qr3hTM1Lj6+5osLCzuJPPLWA8Qxg1qG6Ee+fZYsplpxMKfhEtIDS/GAytke+InLDeS5UHl0+Zmfy8fo0Cbm1y+I1ZHDk7wx7Sg9nbIcKkZW61Gytv7C6ceEnmBntayd/9qwtbvVE4CuOiYOZG2XPVFXY4Y8tiAc0GRSHxkpBOeohqI4b6CUorLCeueFcEhtBSk2AR4NVDPQBHBcTWtlRAnm5NPp67gPODINdmJ2Pf2nXJnWdXId1li0fs2vrsxG8cTqNvYilHACZBzE8DHw/jz9uM/aOpE1daAcKhc+cJ6j6xEn+vVUckSqVZScQKfWYy69+EB75j2NmNetb03SzPNGIr7uRNyA6mH0/zNtSpIs1/bGx4UvaQUj1VJCZUdyNThnxGgHDu/pa8Ixn3QxKXiB5mxuItjLl3T89Rz2JD4EwSIViuo6brGvX4cBYvJevAyYVvJE00JIPm7ol6WQepOw43XJ7xoqbfWwhaU/lFxtnoFnPo/teaMnTNB/hrrqCmnEz6dRmWCn87PxYAu9M9hkxjpTp431+6KEPbZwLT5aG4sya3sbci/Y74sMpMXFB4PMdawZymixiKpwJ3W6vy6EP6hQfJDvG6nXt6WpNLRjSoBaCjK99yMhvTjwELeuZu763RC1+7pCNYUaKFFCi8p5nF5ZIlTW0BN9rwIThZW16e0nvJdJTgxzAuXCYNoRQR+pwQMaOzMlZNeTeoHoH/OIJsvHLrirtG+jPid2p53T5WzvPHfXKOppAbvQg4y14+b/lLrsppHVS1cFBlWsd0XYwDbruIH167ADbkfJEd5BYLEaF//nhcVz6zaaetbdXRJd0xeaScuTiSbL7SYI49jZb5MY7v2FTjb7/wKkkhNpHQ4AAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1jy7rhfa';
export var collapsedIcon = '_1jy7rhf9';
export var collapsedIconContainer = '_1jy7rhf8';
export var contentContainer = '_1jy7rhfc';
export var draggedOverEffect = '_1jy7rhfe';
export var draggingContainer = '_1jy7rhfd';
export var iconContainer = '_1jy7rhf7';
export var itemContent = '_1jy7rhf5';
export var itemMain = '_1jy7rhf3';
export var itemRenameAnchor = '_1jy7rhf4';
export var itemRoot = '_1jy7rhf2';
export var levelIndent = 'var(--_1jy7rhf0)';
export var linkItemRoot = '_1jy7rhf1';
export var mobileCollapsedIconContainer = '_1jy7rhfi _1jy7rhf8';
export var mobileContentContainer = '_1jy7rhfk _1jy7rhfc';
export var mobileIconContainer = '_1jy7rhfh _1jy7rhf7';
export var mobileItemContent = '_1jy7rhfj _1jy7rhf5';
export var mobileItemMain = '_1jy7rhfg _1jy7rhf3';
export var mobileItemRoot = '_1jy7rhff _1jy7rhf2';
export var postfix = '_1jy7rhf6';