import "../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61UwW6jMBC99ytGlVbaPbgKlKaNK/XSD6mMPYC1YLO2SdNd9d9XGCeLgbRpNifkwZ73Zt68uXlJ8JcoN9UK/lwB5NoINBSSdgdW11LAlpnvhLCikArJ8JtwXWvz4/FwnxgmZGcpJOt214dbJoRUJYVbH3i/utnDJB5GSNvW7I1CUaN/0H+JkAa5k1pR4LruGtX/KVlL4WFI2zBTSkVy7ZxuKCTpJHnqkxdaOfKKsqwchbvV6nEfs/I30rggH99ZX0vdB6rwLl0NkL7UyaPcMCUOTRjh356Hb5sF/PQ4vsOdI62RDTNvSzQyT+PklxPG6xnjNLQ/IjhT9u4sZZNQJ6tlqYh02FgK1jHjFgRPswnmehlzlHl0+d5fbqQir1K4qp/WIPKhpvvhPJ3qUP9FhE2y0Ex0Dg2xLePeKST5FrF9+FhEbNqKWWlHKgbTkRoLN7MiMQF+Ktvm38x+4o+o/Gwo/2TPeNIWuVbiyNRu4AnYVyfXhwVybdgwXp0SaHpWUW72H7M5SpNfJg2/4Jr4rOXzls3oR9bjqByaA/ep4Tg8gd2WR2WKVyNAcFo6MdpwHuUVZ3V2yg7njc1O3/9fGNupClnstcGB+xXeaisH+gZr5uQ2nk2kNMdCGwxNVQ6Vo3B9PepfFrcvW1xTAS5n/GdpdKcEBd4Zg8o997RjKiy3uu4cDqvIL4ygkdPt/vD+Fz9rsnAeCAAA\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionBlock = '_1eqdg9h5';
export var actionButtons = '_1eqdg9h6';
export var agreement = '_1eqdg9h9';
export var benefitGroup = '_1eqdg9hb';
export var benefitItem = '_1eqdg9he';
export var benefitList = '_1eqdg9hd';
export var benefitTitle = '_1eqdg9hc';
export var benefits = '_1eqdg9ha';
export var card = '_1eqdg9h0';
export var learnAIButton = '_1eqdg9h8 _1eqdg9h7';
export var purchaseButton = '_1eqdg9h7';
export var title = '_1eqdg9h4';
export var titleBlock = '_1eqdg9h1';
export var titleCaption1 = '_1eqdg9h2';
export var titleCaption2 = '_1eqdg9h3';