import "../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWbW/aMBD+zq+wkCYVqUYOhZal6g+Zpmq6OJfg4diZ7QTo1P8+xQmQN7JVWj8Rzr7zPc/dPfbyR7DX63Lzi5HfM0J2KNKdC8mG5cfnGSGxsLmEU0gSid4AUqSKCoeZDQlH5dBU5hTykGxrnxziWKg0JIw81pZImxgNNRCLwoZkXVt14aRQGBKlFVaGg4jdLiQBY1+qvxkcaWPasCYfrqU2ISnB3FEKSSIUUodHR3MjMjAn6jcsnmfvs+UFWbjTJRqPjxfGVgFyLc6pR8D3qdGFintxvddYwMCHakJQLFE5e0Whc+DCnULCltuu20geMSZQSNfPo47V8l11qrNeNQyWaBKpDyHZiThG1XF58C6DAla/NBYGuRNahRWjRaYGpfXbrAPjc3MGlE20ycL6U4LDb3dscVkTdbDLPsKWG0t4EQlOI3wTaO6W6839chXcs/tgQZjtwvsegwMK3IkSX+bOFDh/JT0o40nQioxufdZ+e6+bPKDUVGQF49wN+f0AeT8L60RS9Z9yqFx7NtoENc3xf+lZ18vulOPLXGmTgZy/eg6uvTgVLwFpbwes+3s64MYvJlo5asUb9gaptme+W6SfrIbnVcOz33C4SA+rbIVFQy1K5K41WyNF8+N/XUApRW6F9YKyEw6pzYF7lTkYyEd05lJloXx2kdR834H3OD5KXvQaCDd0cZKSox2hhI1Qsq4pGRO/SALf0w1b9BK2ZdqZgkaJz8ec/4+FFFwrapFrFYM5dQM/fQ4TU81xU/IvKY5p9NNnMbD9e6tP1bV1OTbXZSZUb1frtK8fFfEJHaqcbopf60y4qm2jWiAlYcvgwRIE276qWZvRM8SxIc3ApEJRiYlrvP71ZTEBKJfAcegx/tqI9JHaHcRVXmc5aV+5Hm8OBpW/8QYPhZEHRY+xhpMzDR3I22ESnb6pjTRYTL5Iaj3b9oY98qeXwopISC/OV95v6PUAwTCG/5bdF03wPHv/AyeXR+0xCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = '_1ko4v5q0';
export var disable = '_1ko4v5q1';
export var settingButton = '_1ko4v5qa';
export var showOnCardHover = '_1ko4v5qb';
export var workspaceActiveStatus = '_1ko4v5q7';
export var workspaceInfo = '_1ko4v5q4';
export var workspaceInfoSlide = '_1ko4v5q3';
export var workspaceInfoSlider = '_1ko4v5q2';
export var workspaceInfoTooltip = '_1ko4v5q8';
export var workspaceName = '_1ko4v5q5';
export var workspaceStatus = '_1ko4v5q6';
export var workspaceTitleContainer = '_1ko4v5q9';