import "../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUzY6bMBC+5yl8qdQ9eAVZmm7Jw6yMPcB0jY3sIbBb9d0rm6YhLqwS7SmKZ/g838/48SVvoJDTkLFfO8Z665HQmpKJyls9EBx3jCn0vRZvJas1TOEg/HKFDuTcLK0eOhMqLWDTUsnyLPty3P3ePZ7x84hfW0Pc4zuU7CTcV85FXaMBHs9bXjwcz03jX6BDloWzTrgGDa8ske1KVjz30xX8PsInXYe06yl2VUK+Ns4ORnFptXXJLIsygSMU7m3uuwy3xWDyD3fq9XPwhHW4wBAYKpkEQ+BCqRF9yfKijwi9UApNE4SdDyrrFDjuhMLBlyxlWkSma/TAOetSQldqL2C+za6F8Rtnx5Ll0Qw0/J/R++TmQ/zkPwmExsZwJOj8kuQH/BMzn/Yz8ShLkVz6ff3Sbd0jyj6FeV6H2Rxy8emPz9H2vZDAK6ARIA44oqJ2ZY/EZY+SFVkzm2Ai7kFao65jnIibxqe6V88NnnOGswRdboZzc95b1s6ewNU6pLRFpWYZI+ClAFpj79FfbSkfoXpF4pWNJM9/dcCXWnQhKctCZSduHUbfTuGFkEJ/ZJmKdK+qtyflFuJ3KRktSf2G5fOpoaaSiYHsIiluzlp4Hv4A30Ij0jQGAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRightIcon = '_1ge4cxue';
export var description = '_1ge4cxuc';
export var feedback = '_1ge4cxud';
export var promptArt = '_1ge4cxu2';
export var promptDisclaimer = '_1ge4cxu6';
export var promptDisclaimerConfirm = '_1ge4cxu8';
export var promptRoot = '_1ge4cxu0';
export var promptTitle = '_1ge4cxu1';
export var promptWarning = '_1ge4cxu3';
export var promptWarningTitle = '_1ge4cxu4';
export var rowContainer = '_1ge4cxub';
export var settingsContainer = '_1ge4cxu7';
export var spacer = '_1ge4cxu5';
export var subHeader = '_1ge4cxua';
export var switchRow = '_1ge4cxu9';