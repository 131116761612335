import "../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WTW/bMAy991cQ3WU9KLDTfHQqdtnfGIZBtmhbrSIZktymG/bfB8lxKjt2agM9GZBE8vGRfPTqd7qp5C6TCfy9ATgwUwpFnK4p3Cf18fHm382qe5JGTyiwxunHd5NMO6cPFDbeCMDh0REmRako5Kgcmp6r9UQ0gFxLbSi8MPOVEFYUQiEJzizmWnFm3kh4cucfF1o5YsUfHBiE86MNb6Q/qFCUlaOQrrY9HPfLcVSirKT3RgptsDS6UZwY5J8DaA0sQBoLL4V6Piffs1n5q9auMdYb1lq0pC9ztQlOam2FE9oXObNaNg69H9MiTh4HjJH1btAo25jVrjEu2mk3L1GAjOXPLc8UnGHK1sygcuFKG46GnJwMLhcXY71ua99YNMSixNxRUFphD/c+4L7q3B7uFvfyx6R3TCY9OA8BTpfCft+mwIWtJXujUEgMB/5LuDCYt0FyLZuD8jdPjXWi8FCUQ+Xe5xUgDDARDg82Pr6qEt8Cnov4V6KUrKbw0OIercfHXEfxp+cnUF8bcRgRkddTyF3SZzcL3l4FdxWFzXaWuOU9m2TaZl4XndiWWHi92E3LUyYbvMxoM8iIB3TjcJZUbaI35gxdD982uVCU3Ynn4WoZthqOt9ocCOcWS6IWG0CaqaV1Y5C8VsLhQK3IaJEM627asWecC1VSSLrVedI0w7ho7HmhLq1ETNNPzhwjwpIMpcAXNN9vnWnw9lfg7xLwl/v9Jt2mPSfFONdhdtPTnE60xJDruNSd6aDU7XEUvmxFV+KRQho7TSLKztb1EayWgg/WCntDQ4SyguOPzsJ/+gJSXReQs3bPXPiRZzFLmmb/SFz2bBTr6Xosv2KXJ/A8y2lkICcmdHIZjffyf1axftGmCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_14hl6bld';
export var authMessage = '_14hl6bl2';
export var authModalContent = '_14hl6bl0';
export var captchaWrapper = '_14hl6bl1';
export var errorMessage = '_14hl6bl3';
export var forgetPasswordButton = '_14hl6bl7';
export var forgetPasswordButtonRow = '_14hl6bl4';
export var linkButton = '_14hl6bl6';
export var resendCountdown = '_14hl6blb';
export var resendCountdownInButton = '_14hl6blc';
export var resendWrapper = '_14hl6bl8';
export var sendMagicLinkButtonRow = '_14hl6bl5';
export var sentMessage = '_14hl6bla';
export var sentRow = '_14hl6bl9';
export var skipDivider = '_14hl6blf';
export var skipDividerLine = '_14hl6blg';
export var skipDividerText = '_14hl6blh';
export var skipLink = '_14hl6blj';
export var skipLinkIcon = '_14hl6blk';
export var skipSection = '_14hl6bll';
export var skipText = '_14hl6bli';
export var userPlanButton = '_14hl6ble';